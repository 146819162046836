import styled from "styled-components";

export { default as Account } from "./Account";
export { default as Contract } from "./Contract";
export { default as Address } from "./Address";
export { default as AddressInput } from "./AddressInput";
export { default as EtherInput } from "./EtherInput";
export { default as Balance } from "./Balance";
export { default as TokenBalance } from "./TokenBalance";
export { default as Provider } from "./Provider";
export { default as Ramp } from "./Ramp";
export { default as Faucet } from "./Faucet";
export { default as Wallet } from "./Wallet";
export { default as Blockie } from "./Blockie";
export { default as Header } from "./Header";
export { default as Timeline } from "./Timeline";
export { default as GasGauge } from "./GasGauge";
export { default as Projects } from "./Projects";


export const Body = styled.body`
  background-color: #f4f4f5;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
`;